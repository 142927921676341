import { create } from 'zustand'

export const useStrStore = create((set) => ({
    str: 'swop VVUSDNLP / XTN (https://swop.fi/pool?address=3PCkJuWHu1QJLHvcdUegYczfNVohS6c1A6o) 235%  \n' +
        'axly WBTC / XTN (https://app.axly.io/add-to-farm?address=3PACj2DLTw3uUhsUmT98zHU5M4hPufbHKav) 227% \n' +
        'WX WAVES / ROME (https://wx.network/liquiditypools/pools/WAVES_ROME) 182%  \n' +
        'Puzzle Swap Pool 10 (https://puzzleswap.org/pools/pool10/invest) 172%  \n' +
        'WX PWR / WAVES (https://wx.network/liquiditypools/pools/PWR_WAVES) 153% \n' +
        'WX L2MP / XTN (https://wx.network/liquiditypools/pools/L2MP_XTN) 106% \n' +
        'WX ROME / USDT-ERC20 (https://wx.network/liquiditypools/pools/ROME_USDT-ERC20) 99%  \n' +
        'Puzzle Lend PWR (http://lend.puzzleswap.org/3PHpuQUPVUoR3AYzFeJzeWJfYLsLTmWssVH/supply/2thsACuHmzDMuNezPM32wg9a3BwUzBWDeSKakgz3cw21) 95%  \n' +
        'axly WETH / XTN (https://app.axly.io/add-to-farm?address=3PNEC4YKqZiMMytFrYRVtpW2ujvi3aGXRPm) 86% \n' +
        'axly WAVES / XTN (https://app.axly.io/add-to-farm?address=3PHaNgomBkrvEL2QnuJarQVJa71wjw9qiqG) 72%',
    updateStr: (newState) => set((s) => ({ str: newState })),
}));

export const useTableRowsLimitStore = create((set) => ({
    tableRowsLimit: 10,
    updateTableRowsLimit: (newState) => set((s) => ({ tableRowsLimit: newState })),
}))

export default {useStrStore, useTableRowsLimitStore};