import {Pane} from 'tweakpane';
import {startCheckDecreaseForAll} from "./fix-font-size";
import html2canvas from 'html2canvas';
import * as TextareaPlugin from '@pangenerator/tweakpane-textarea-plugin';
import * as store from "../store/store";

const setDateTextToHtmlEl = (str) => {
    document.querySelector('.header__date').textContent = str;
}

const createControlGui = () => {
    const localStorageName = 'tweakpane-core_v12';
    const localStorageItem = localStorage.getItem(localStorageName);
    let isStorageImported = false;
    const checkFzAfterStorageImport = () => {
        if((localStorageItem && isStorageImported) || !localStorageItem){
            // startCheckDecreaseForAll();
        }
    }
    const PARAMS = {
        cardSize: 1200,
        inputData: store.useStrStore.getState().str,
        tdFontWeight: 400,
        tdFontStyle: 'normal',
        imageQuality: 1,
        tableRowsLimit: store.useTableRowsLimitStore.getState().tableRowsLimit,
        date: new Date().toLocaleDateString('en-us', { day: 'numeric', year:"numeric", month:"long"}),
    };
    const pane = new Pane({
        title: 'Core',
        container: document.querySelector('#tweakpaneContainer'),
        expanded: true,
    });
    pane.registerPlugin(TextareaPlugin);
    const cardEl = document.getElementById('card');

    // init html
    setDateTextToHtmlEl(PARAMS.date);
    if((localStorageItem && isStorageImported) || !localStorageItem){
        store.useStrStore.getState().updateStr(PARAMS.inputData);
    }

    const fCache = pane.addFolder({
        title: 'Basic (cache)',
    });
    fCache.addBinding(PARAMS, 'cardSize', {
        options: {
            910: 910,
            1200: 1200,
        },
        label: 'card size'
    }).on('change', (ev) => {
        if (ev.last && cardEl) {
            cardEl.style.setProperty('--card-width', ev.value+'px');
            checkFzAfterStorageImport();
        }
    });

    // setTimeout(()=>{
    //     PARAMS.inputData = 'qwe2';
    //     pane.refresh();
    // }, 1000)

    fCache.addBinding(PARAMS, 'tdFontWeight', {
        options: {
            300: 300,
            400: 400,
            600: 600,
        },
        label: 'td font weight'
    }).on('change', (ev) => {
        if (ev.last && cardEl) {
            cardEl.style.setProperty('--td-font-weight', ev.value);
            checkFzAfterStorageImport();
        }
    });
    fCache.addBinding(PARAMS, 'tdFontStyle', {
        options: {
            'normal': 'normal',
            'italic': 'italic',
        },
        label: 'td font style'
    }).on('change', (ev) => {
        if (ev.last && cardEl) {
            cardEl.style.setProperty('--td-font-style', ev.value);
            checkFzAfterStorageImport();
        }
    });


    fCache.addBinding(PARAMS, 'imageQuality', {
        options: {
            'x1': 1,
            'x2': 2,
        },
        label: 'image quality'
    }).on('change', (ev) => {
        if (ev.last && cardEl) {}
    });

    fCache.addBinding(PARAMS, 'tableRowsLimit', {
        step: 1,
        min: 0,
        max: 20,
        label: 'table rows limit'
    }).on('change', (ev) => {
        store.useTableRowsLimitStore.getState().updateTableRowsLimit(ev.value);
    });

    fCache.on('change', (ev) => {
        const state = fCache.exportState();
        localStorage.setItem(localStorageName, JSON.stringify(state));
    });

    const fData = pane.addFolder({
        title: 'data',
    });
    const inputDataBinding = fData.addBinding(PARAMS, 'inputData', {
        view: 'textarea',
        rows: 10,
        label: null,
        placeholder: 'TAG[wx|puzzle swap|puzzle lend|axly|swop] NAME (link) PERCENTAGE'
    }).on('change', (ev) => {
        checkFzAfterStorageImport();
        store.useStrStore.getState().updateStr(ev.value);
    });
    const importDataButton = fData.addButton({
        title: 'Import data',
    });
    importDataButton.on('click', () => {
        fetchData();
    });



    const fDynamic = pane.addFolder({
        title: 'dynamic (no cache)',
    });
    fDynamic.addBinding(PARAMS, 'date',{}).on('change', (ev) => {
        if (ev.last) {
            setDateTextToHtmlEl(ev.value);
        }
    });

    const createImageButton = fDynamic.addButton({
        title: 'create image',
    });
    createImageButton.on('click', () => {
        createImageButton.disabled = true;
        document.body.classList.add('html2canvas');
        html2canvas(cardEl, {
            scale: PARAMS.imageQuality, // TODO спросить 1 - говнокачество, 2 - x2 размер
            scrollX: 0,
            scrollY: -window.scrollY,
            backgroundColor: null,
        }).then((canvas) => {
            const imageDataURL = canvas.toDataURL("image/png");
            const a = document.createElement("a");
            a.href = imageDataURL;
            a.download = `card--width-${PARAMS.cardSize}.png`;
            a.click();
            createImageButton.disabled = false;
            document.body.classList.remove('html2canvas');
        });
    });


    if(localStorageItem){
        fCache.importState(JSON.parse(localStorageItem));
        isStorageImported = true;
        store.useStrStore.getState().updateStr(PARAMS.inputData);
    }

    const fetchData = () => {
        fData.disabled = true;
        fetch('/data').then((response) => {
            if (response.ok) {
                return response.json();
            }
            throw new Error('Something went wrong');
        })
            .then((data) => {
                // Do something with the response
                putDataToTextArea(data);
                checkFzAfterStorageImport();
                fData.disabled = false;
            })
            .catch((error) => {
                console.log(error);
                fData.disabled = false;
            });
    }

    const putDataToTextArea = (data) => {
        const str = data.map((el, i)=>{
            return `${el.dex.toLowerCase()} ${el.poolName} (${el.link}) ${el.poolMaxAPR}% ${data.length !== i+1 ? '\n' : ''}`
        }).join("");

        PARAMS.inputData = str;
        pane.refresh();
    }

}
export default createControlGui;