export const createTableDataRows = (str) => {
    if(typeof str !== "string") return
    const newStr = str
        .replaceAll('\n', '')
        .split('%')
        .map( el => el.trim())
        .filter(el => el !== '')
        .map( el => el+'%');

    const rows = newStr.flatMap((rowStr)=>{
        const regexTag = /^(wx|puzzle swap|puzzle lend|axly|swop)/i;
        const tag = rowStr.match(regexTag) ? rowStr.match(regexTag)[0].toLowerCase().trim() : null;

        const regexName = /(wx|puzzle swap|puzzle lend|axly|swop)\s(.*?)\(/i;
        const name = rowStr.match(regexName) ? rowStr.match(regexName)[2].trim() : null;

        const regexPercentage = /(\d+%)/;
        const percentage = rowStr.match(regexPercentage) ? rowStr.match(regexPercentage)[1].trim() : null;
        if(tag && name && percentage) {
            return { tag, name, percentage };
        }else{
            return { tag: "error", name: "error", percentage: "error" };
        }
    });
    return rows;
}