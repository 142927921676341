const isOverflowY = (el) => {
    if (!el) {
        return false;
    }
    return el.scrollHeight > el.clientHeight;
}
export const checkDecreaseFz = (el) => {
    if (!el) {
        return;
    }
    el.style.removeProperty('font-size');
    el.style.opacity = 0;
    let fontSize = parseInt(
        window
            .getComputedStyle(el, null)
            .getPropertyValue('font-size'),
        0
    ); //.toFixed(2);
    const decrease = () => {
        let overflow = isOverflowY(el);
        if (overflow && fontSize > 1) {
            fontSize -= 1;
            el.style.fontSize = fontSize+'px';
            decrease();
        } else {
            el.style.opacity = 1;
            return false;
        }
    };
    decrease();
}

export const startCheckDecreaseForAll = () => {
    document.querySelectorAll('.table__row-title').forEach((el)=>{
        checkDecreaseFz(el);
    });
}
