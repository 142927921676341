import React from "react";
export const svgChoose = (id) => {
    switch (id) {
        case "error":
            return <svg xmlns="http://www.w3.org/2000/svg" width="43" height="43" viewBox="0 0 43 43" fill="none">
                <circle cx="21.5" cy="21.5" r="21.5" fill="#FF0000"/>
                <path d="M33.9902 7.99951L7.99023 33.9995" stroke="white" strokeWidth="3"/>
                <path d="M8 8.00012L34 34.0001" stroke="white" strokeWidth="3"/>
            </svg>;
        case 'wx':
            return <svg id="wx" width="43" height="43" fill="none" viewBox="0 0 43 43">
                <circle cx="21.133" cy="21.133" r="21.133" fill="#fff"/>
                <path fill="#365CE4" fillRule="evenodd" d="m20.529 21.741 11.525 11.45H9.004l11.525-11.45Z"
                      clipRule="evenodd"/>
                <path fill="#C83297" fillRule="evenodd" d="m20.529 21.741 11.63-11.45H9.005l11.525 11.45Z"
                      clipRule="evenodd"/>
            </svg>
            break;
        case "puzzle":
            return <svg id="puzzle" width="43" height="43" fill="none" viewBox="0 0 43 43">
                <circle cx="21.133" cy="21.133" r="21.133" fill="#7075E9"/>
                <path fill="#fff"
                      d="M25.819 11.48c-.446.446-1.211.581-1.657.136l-1.937-1.937a1.255 1.255 0 0 0-1.775 0l-1.937 1.937c-.446.445-1.21.31-1.657-.136a3.802 3.802 0 0 0-5.377 5.377c.446.446.582 1.211.136 1.657L9.678 20.45c-.49.49-.49 1.284 0 1.774l1.937 1.937c.446.446 1.21.31 1.657-.135a3.802 3.802 0 0 1 5.377 5.377c-.446.446-.582 1.21-.136 1.657l1.937 1.937c.49.49 1.285.49 1.775 0l1.937-1.937c.446-.446 1.21-.31 1.657.135a3.802 3.802 0 0 0 5.377-5.377c-.446-.446-.582-1.21-.136-1.657l1.937-1.937c.49-.49.49-1.284 0-1.774l-1.937-1.937c-.446-.446-.31-1.211.136-1.657a3.802 3.802 0 0 0-5.378-5.377Z"/>
            </svg>
            break;
        case "puzzle swap":
            return <svg id="puzzle" width="43" height="43" fill="none" viewBox="0 0 43 43">
                <circle cx="21.133" cy="21.133" r="21.133" fill="#7075E9"/>
                <path fill="#fff"
                      d="M25.819 11.48c-.446.446-1.211.581-1.657.136l-1.937-1.937a1.255 1.255 0 0 0-1.775 0l-1.937 1.937c-.446.445-1.21.31-1.657-.136a3.802 3.802 0 0 0-5.377 5.377c.446.446.582 1.211.136 1.657L9.678 20.45c-.49.49-.49 1.284 0 1.774l1.937 1.937c.446.446 1.21.31 1.657-.135a3.802 3.802 0 0 1 5.377 5.377c-.446.446-.582 1.21-.136 1.657l1.937 1.937c.49.49 1.285.49 1.775 0l1.937-1.937c.446-.446 1.21-.31 1.657.135a3.802 3.802 0 0 0 5.377-5.377c-.446-.446-.582-1.21-.136-1.657l1.937-1.937c.49-.49.49-1.284 0-1.774l-1.937-1.937c-.446-.446-.31-1.211.136-1.657a3.802 3.802 0 0 0-5.378-5.377Z"/>
            </svg>
            break;
        case "puzzle lend":
            return <svg xmlns="http://www.w3.org/2000/svg" width="43" height="43" fill="none" viewBox="0 0 43 43">
                <circle cx="21.133" cy="21.133" r="21.133" fill="#F88545"/>
                <path fill="#fff"
                      d="M25.819 11.48c-.446.446-1.211.581-1.657.136l-1.937-1.937a1.255 1.255 0 0 0-1.775 0l-1.937 1.937c-.446.445-1.21.31-1.657-.136a3.802 3.802 0 0 0-5.377 5.377c.446.446.582 1.211.136 1.657L9.678 20.45c-.49.49-.49 1.284 0 1.774l1.937 1.937c.446.446 1.21.31 1.657-.135a3.802 3.802 0 0 1 5.377 5.377c-.446.446-.582 1.21-.136 1.657l1.937 1.937c.49.49 1.285.49 1.775 0l1.937-1.937c.446-.446 1.21-.31 1.657.135a3.802 3.802 0 0 0 5.377-5.377c-.446-.446-.582-1.21-.136-1.657l1.937-1.937c.49-.49.49-1.284 0-1.774l-1.937-1.937c-.446-.446-.31-1.211.136-1.657a3.802 3.802 0 0 0-5.378-5.377Z"/>
            </svg>
            break;
        case "swop":
            return <svg id="swop" width="43" height="43" fill="none" viewBox="0 0 43 43">
                <g clipPath="url(#swopClip)">
                    <circle cx="21.133" cy="21.133" r="21.133" fill="#12A17E"
                            transform="scale(-1 1) rotate(20 .21 -98.573)"/>
                    <path fill="#173937" fillRule="evenodd"
                          d="M33.032 20.197a12.024 12.024 0 0 0-.698-3.273c-2.264-6.22-9.135-9.428-15.348-7.166a11.966 11.966 0 0 0-7.736 9.423l4.62 1.7a7.216 7.216 0 0 1 11.895-5.359l-1.935 1.317 4.078 1.468 5.124 1.89Zm-.186 3.081c-.769 4.002-3.56 7.51-7.662 9.004-6.214 2.261-13.085-.947-15.348-7.166a11.968 11.968 0 0 1-.658-2.829l4.359 1.605 4.329 1.558-1.506 1.026a7.21 7.21 0 0 0 7.195 1.331 7.219 7.219 0 0 0 4.725-6.209l4.566 1.68Z"
                          clipRule="evenodd"/>
                    <path fill="#fff" fillRule="evenodd"
                          d="M33.03 20.198a12.024 12.024 0 0 0-.698-3.273c-2.264-6.22-9.134-9.427-15.348-7.166a11.966 11.966 0 0 0-7.735 9.423l4.618 1.7a7.216 7.216 0 0 1 11.895-5.359l-1.934 1.317 4.079 1.468 5.124 1.89Z"
                          clipRule="evenodd"/>
                </g>
                <defs>
                    <clipPath id="swopClip">
                        <path fill="#fff" d="M0 0h42.14v42.14H0z"/>
                    </clipPath>
                </defs>
            </svg>
            break;
        case "axly":
            return <svg id="axly" width="43" height="43" fill="none" viewBox="0 0 33 33">
                <path fill="url(#axly-gradient-a)"
                      d="M32.634 16.5c-.202 9.113-7.59 16.5-16.5 16.5S.164 25.613.366 16.5C.57 7.387 7.956 0 16.866 0s15.97 7.387 15.768 16.5Z"/>
                <path fill="url(#axly-gradient-b)" fillOpacity=".2"
                      d="M32.634 16.5c-.202 9.113-7.59 16.5-16.5 16.5S.164 25.613.366 16.5C.57 7.387 7.956 0 16.866 0s15.97 7.387 15.768 16.5Z"/>
                <path fill="url(#axly-gradient-c)"
                      d="M30.764 8.345a16.929 16.929 0 0 1 1.87 8.155h-3.57L23.008 5.42l1.818-3.16v-.006l.027-.072a15.932 15.932 0 0 1 5.911 6.163Z"/>
                <path fill="url(#axly-gradient-d)"
                      d="m24.21 30.817-.039-.121-1.64-3.11-.011-.009L29.018 16.5h3.616a17.004 17.004 0 0 1-8.425 14.317Z"/>
                <path fill="url(#axly-gradient-e)"
                      d="M24.208 30.817A16.049 16.049 0 0 1 16.133 33c-2.959 0-5.714-.815-8.066-2.236l1.892-3.24h.002l.033.06h12.518l.007-.009.01.009 1.64 3.11v.002l.039.12Z"/>
                <path fill="url(#axly-gradient-f)"
                      d="M24.813 2.26v-.006l.027-.072A15.527 15.527 0 0 0 16.864 0c-2.911 0-5.66.789-8.049 2.169l.005.083v.006l1.657 3.156h12.53v.006l1.806-3.16Z"/>
                <path fill="url(#axly-gradient-g)"
                      d="M2.861 7.993a16.933 16.933 0 0 1 5.96-5.827.638.638 0 0 0-.002.084h.002v.008l1.657 3.156h.007v.002L3.982 16.5H.366A16.992 16.992 0 0 1 2.86 7.993Z"/>
                <path fill="url(#axly-gradient-h)"
                      d="M1.868 23.808A16.765 16.765 0 0 1 .365 16.5h3.616l5.98 11.027H9.96L8.1 30.691v.007l.006.089a15.91 15.91 0 0 1-6.238-6.98Z"/>
                <path fill="url(#axly-gradient-i)"
                      d="M25.912 16.5c-8.709.385-9.051.727-9.625 9.625-.22-8.898-.506-9.24-9.198-9.625 8.71-.428 9.01-.727 9.625-9.625.18 8.898.507 9.197 9.198 9.625Z"/>
                <defs>
                    <radialGradient id="axly-gradient-b" cx="0" cy="0" r="1"
                                    gradientTransform="rotate(91.271 .181 16.32) scale(16.5041 16.13)"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="#fff" stopOpacity="0"/>
                        <stop offset=".807" stopColor="#fff"/>
                    </radialGradient>
                    <radialGradient id="axly-gradient-c" cx="0" cy="0" r="1"
                                    gradientTransform="matrix(-5.97017 16.15333 -11.07386 -4.09283 31.84 9.625)"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="#305DDD" stopOpacity="0"/>
                        <stop offset="1" stopColor="#fff"/>
                    </radialGradient>
                    <radialGradient id="axly-gradient-d" cx="0" cy="0" r="1"
                                    gradientTransform="rotate(83.725 2.052 30.463) scale(12.7954 8.85869)"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="#305DDD" stopOpacity="0"/>
                        <stop offset="1" stopColor="#fff"/>
                    </radialGradient>
                    <radialGradient id="axly-gradient-e" cx="0" cy="0" r="1"
                                    gradientTransform="rotate(143.663 4.887 19.047) scale(8.35823 9.61403)"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="#305DDD" stopOpacity="0"/>
                        <stop offset="1" stopColor="#fff"/>
                    </radialGradient>
                    <radialGradient id="axly-gradient-f" cx="0" cy="0" r="1"
                                    gradientTransform="matrix(-19.38085 -5.15612 6.19853 -23.29906 28.14 6.875)"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="#305DDD" stopOpacity="0"/>
                        <stop offset="1" stopColor="#fff"/>
                    </radialGradient>
                    <radialGradient id="axly-gradient-g" cx="0" cy="0" r="1"
                                    gradientTransform="rotate(105.798 -.888 9.326) scale(10.7173 7.31104)"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="#305DDD" stopOpacity="0"/>
                        <stop offset="1" stopColor="#fff"/>
                    </radialGradient>
                    <radialGradient id="axly-gradient-h" cx="0" cy="0" r="1"
                                    gradientTransform="matrix(1.78037 10.6563 -7.40064 1.23644 4.857 26.125)"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="#305DDD" stopOpacity="0"/>
                        <stop offset="1" stopColor="#fff"/>
                    </radialGradient>
                    <linearGradient id="axly-gradient-a" x1="8.07" x2="1.776" y1="32.999" y2="11.194"
                                    gradientUnits="userSpaceOnUse">
                        <stop offset=".164" stopColor="#FFB195"/>
                        <stop offset=".416" stopColor="#E15C99"/>
                        <stop offset="1" stopColor="#0654EC"/>
                    </linearGradient>
                    <linearGradient id="axly-gradient-i" x1="35.283" x2="23.458" y1="-4.992" y2="41.352"
                                    gradientUnits="userSpaceOnUse">
                        <stop offset="1" stopColor="#fff"/>
                    </linearGradient>
                </defs>
            </svg>
        default:
            return ''
    }


}